import React from 'react';
import Nav from '../components/nav';

export default () => {

    return (
        <>

            <section>


                <Nav />

            </section>



            <div id="private_equity" className="container-fluid" style={{background: "url(./assets/background-3.jpg)", backgroundSize: "cover"}}>
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center pt-5">
                        <p className="p-0 m-0 mb-5">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>PRACTICE AREA</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>PRIVATE EQUITY</span>
                        </p>
                        <h2 className="mb-0 text-uppercase bold-font">
                            Private equity enables investors the opportunity to invest in unlisted businesses before they become public.
                            Ferguson capital have links with many opportunities that require funding and many HNW AND UHNW Investors that
                            enjoy participation in Private equity. These opportunities can be wide ranging from renewable energy through
                            to Crypto currencies, technology applications and medical technology.
                        </h2>
                    </div>
                    <p className="p-0 m-0 mb-5">
                        <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                        <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                    </p>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center mt-5">
                        <p className="lead text-center mb-0 mb-0">FOUNDERS OF</p>
                        <p className="lead text-center"><span className="bold-font display-1">FRUITFULL</span><span
                            className="display-1">OUTCOMES</span></p>
                    </div>
                </div>

            </div>

            <footer>
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center">
                        <p className="p-0 m-0 mb-3">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                        </p>

                        <p>00 (44) 777 5602904</p>
                        <p className="mb-3">71 – 75 SHELTON STREET, COVENT GARDEN, LONDON WC2H 9JQ</p>


                        <p className="p-0 m-0 text-uppercase">© Copyright 2021.</p>
                        <p className="p-0 m-0 text-uppercase" style={{fontSize: '.7rem'}}>
                            ferguson capital®. All rights reserved_ The contents of this document are the property of ferguson
                            capital®. They represent the intellectual property in the form of, but not limited to, processes, ideas
                            and creative designs. They may not be used without prior written agreement and only upon full compensation
                            to ferguson capital® for the use or partial use of any of the material contained herein.
                        </p>
                    </div>
                </div>
            </footer>


        </>
    );
}