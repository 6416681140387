import React from 'react';
import Nav from '../components/nav';

export default () => {

    return (
        <>

            <section>


                <Nav />




            </section>



            <div id="venture_captial" className="container-fluid" style={{background: "url(./assets/background-2.jpg)", backgroundSize: "cover"}}>
                <div className="row pt-5 pb-5">
                    <div className="col offset-md-1 col-12 col-md-10 text-center pt-5">
                        <p className="p-0 m-0 mb-5">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>PRACTICE AREA</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>VENTURE CAPITAL</span>
                        </p>
                        <h2 className="mb-0 text-uppercase bold-font">
                            In a business’s infancy the founders need to raise start-up Capital. Ferguson Capital can assist with Venture
                            Capital raises at each of the VC stages. We can also help startup ventures with strategic direction, branding,
                            technology and marketing. We will look at board positions for the right young companies.
                        </h2>
                    </div>
                    <p className="p-0 m-0 mb-5">
                        <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                        <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                    </p>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row pt-5 pb-5">
                    <div className="col offset-md-2 col-12 col-md-8 text-center">
                        <p className="p-0 m-0 ">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '2rem'}}>MIST-DEFI</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '2rem'}}>ANTI-FOG RUB</span>
                        </p>

                        <p className="p-0 m-0">
          <span className="navbar-brand m-0 bold-font text-uppercase"
                style={{fontSize: '.7rem'}}>strategic direction</span>
                            <span className="navbar-brand m-0 text-uppercase" style={{fontSize: '.7rem'}}>branding & marketing</span>
                        </p>
                        <p className="p-0 m-0 mb-5">
                            <span className="navbar-brand m-0 bold-font text-uppercase" style={{fontSize: '.7rem'}}>deployed</span>
                            <span className="navbar-brand m-0 text-uppercase" style={{fontSize: '.7rem'}}>june 2020</span>
                        </p>


                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="./assets/slide-1.jpg" className="d-block w-100" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="./assets/misty.gif" className="d-block w-100" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="./assets/mist.jpg" className="d-block w-100" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="./assets/misty-2.gif" className="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                                    data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                                    data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>


                    </div>
                    <p className="p-0 m-0 mt-3">
                        <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                        <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                    </p>
                </div>
            </div>


            <footer>
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center">
                        <p className="p-0 m-0 mb-3">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                        </p>
                        <p>00 (44) 777 5602904</p>
                        <p className="mb-3">71 – 75 SHELTON STREET, COVENT GARDEN, LONDON WC2H 9JQ</p>


                        <p className="p-0 m-0 text-uppercase">© Copyright 2021.</p>
                        <p className="p-0 m-0 text-uppercase" style={{fontSize: '.7rem'}}>
                            ferguson capital®. All rights reserved_ The contents of this document are the property of ferguson
                            capital®. They represent the intellectual property in the form of, but not limited to, processes, ideas
                            and creative designs. They may not be used without prior written agreement and only upon full compensation
                            to ferguson capital® for the use or partial use of any of the material contained herein.
                        </p>
                    </div>
                </div>
            </footer>


        </>
    );
}