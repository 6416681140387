import React from 'react';

import Nav from '../components/nav';

export default () => {

    return (
        <>

            <section className="background" style={{backgroundImage: 'url("/assets/background.jpg")', backgroundSize: 'cover'}}>


               <Nav />


                <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">


                    <main className="px-3
     mt-auto text-center">
                        <p className="lead text-center mb-0">FOUNDERS OF</p>
                        <p className="lead text-center"><span className="bold-font display-1">FRUITFULL</span><span
                            className="display-1">OUTCOMES</span></p>


                    </main>
                    <p className="lead mt-auto bold-font">FIND OUT MORE</p>
                    <i className="bi bi-arrow-down"></i>
                </div>
            </section>


            <div className="container-fluid dark">
                <div className="row pt-5 pb-5">
                    <div className="col offset-md-3 col-12 col-md-6 text-center">
                        <p className="mb-0" style={{fontSize: "1.2rem"}}>
                            Ferguson Capital was formed to provide solutions for large of pools of Family Capital and generate potential
                            investment opportunities in partnership with family offices, privately-owned companies, conglomerates and
                            related entities.
                        </p>
                    </div>
                </div>
            </div>



            <div className="container-fluid">
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center mt-5">
                        <p className="lead text-center mb-0 mb-0">FOUNDERS OF</p>
                        <p className="lead text-center"><span className="bold-font display-1">FRUITFULL</span><span
                            className="display-1">OUTCOMES</span></p>
                    </div>
                </div>



                <div className="container-fluid" style={{background: "url(./assets/background-5.jpg)", backgroundSize: "cover"}}>
                    <div className="row pt-5 pb-5">
                        <div className="col offset-1 col-10 text-center pt-5">
                            <p className="p-0 m-0 mb-5">
                                <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>OUR</span>
                                <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>TEAM</span>
                            </p>
                            <h2 className="mb-0 text-uppercase bold-font">
                                At age 19 Chris Ferguson joined Edward Jones in London as a their youngest ever
                                Investment representative. This enabled the ability to attain a variety of professional
                                examinations and holistic Investment experience to be undertaken at a young age and a
                                posting out to head office in St Louis, Missouri, USA. It was this overseas experience that
                                gave Chris the lust for travel and international life. After a foundation of experience in the
                                UK as a successful financial advisor Chris packed his bags to Australia.
                                In 2006 Chris then moved to Dubai to continue his financial planning career and spent the
                                next 14 years advising HNW clients from all over the world on their financial affairs,
                                progressing to Managing Director of the now Skybound Wealth. Chris then decided to setup,
                                build, grow and ultimately sell his own financial planning business Credence International.
                                In 2020 during the covid Pandemic Chris returned to the UK and decided to focus on the
                                needs of a very select few (U)HNW clients and property developers. Chris is focussed on
                                private equity and real estate acquisitions for the clients he works with. Chris also heads
                                Ferguson Capital and ensures that any other members follow his strict processes to ensure
                                maximum efficiencies, discretion and absolute client focus.
                                Chris believes Ferguson capital benefits from his 20 years of worldwide Investment
                                experience and huge network of Investment professionals. Chris keeps his financial planning
                                qualifications upto date and undertakes constant learning whilst insourcing further
                                expertise when required to explore the finer details of a deal.
                            </h2>
                        </div>
                        <p className="p-0 m-0 mb-5">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                        </p>
                    </div>
                </div>

            </div>

            <footer>
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center">
                        <p className="p-0 m-0 mb-3">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                        </p>

                        <p>00 (44) 777 5602904</p>
                        <p className="mb-3">71 – 75 SHELTON STREET, COVENT GARDEN, LONDON WC2H 9JQ</p>

                        <p className="p-0 m-0 text-uppercase">© Copyright 2021.</p>
                        <p className="p-0 m-0 text-uppercase" style={{fontSize: '.7rem'}}>
                            ferguson capital®. All rights reserved_ The contents of this document are the property of ferguson
                            capital®. They represent the intellectual property in the form of, but not limited to, processes, ideas
                            and creative designs. They may not be used without prior written agreement and only upon full compensation
                            to ferguson capital® for the use or partial use of any of the material contained herein.
                        </p>
                    </div>
                </div>
            </footer>


        </>
    );
}