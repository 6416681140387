import React from 'react';
import { Link } from 'react-router-dom';

export default () => {

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top dark p-3">
            <p className="p-0 m-0">
                <Link to={'/'} style={{textDecoration: 'none'}}>
                    <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                    <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                </Link>
            </p>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav" style={{flexFlow: "column", alignItems: "flex-end"}}>
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <Link to={'/venture-capital'} className="nav-link text-light">VENTURE CAPITAL</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/private-equity'} className="nav-link text-light">PRIVATE EQUITY</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/real-estate'} className="nav-link text-light">REAL ESTATE</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/proposal'} className="nav-link text-light">SUBMIT YOUR PROPOSAL</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/contact'} className="nav-link text-light">CONTACT</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}