import React from 'react';
import Nav from '../components/nav';

export default () => {

    return (
        <>

            <section>


             <Nav />


            </section>







            <div className="container-fluid">


                <div id="real_estate" className="container-fluid"
                     style={{background: "url(./assets/background-4.jpg)", backgroundSize: "cover"}}>
                    <div className="row pt-5 pb-3">
                        <div className="col offset-1 col-10 text-center pt-5">
                            <p className="p-0 m-0 mb-5">
                                <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>PRACTICE AREA</span>
                                <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>REAL ESTATE</span>
                            </p>
                            <h2 className="mb-0 text-uppercase bold-font">
                                Ferguson Capital have a network of developers, Family offices, land and property owners. We work
                                specifically for each client requirement and will source the perfect Investments for our clients. We welcome
                                conversation on all property types and will act as a property concierge service for a select number of
                                clients.
                            </h2>
                        </div>
                        <p className="p-0 m-0 mb-5">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                        </p>
                    </div>
                    <div className="row pt-0 pb-5">
                        {/*<div className="col col-12 col-md-6 col-lg-3">*/}
                        {/*  <div className="card">*/}
                        {/*    <img src="assets/real_estate-4.png" className="card-img-top" alt="..." />*/}
                        {/*      <div className="card-body">*/}
                        {/*        <h5 className="card-title text-dark bold-font">Chelsea</h5>*/}
                        {/*        <p className="card-text text-dark">Some quick example text to build on the card title and make up the*/}
                        {/*          bulk of the card's content.</p>*/}
                        {/*      </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="col col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src="assets/real_estate-1.png" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 style={{fontSize: '1rem'}} className="mb-3 card-title text-dark bold-font">Tenanted PRS stock</h5>
                                    <p style={{fontSize: '0.8rem'}} className="card-text text-dark">We are constantly seeking investment, private rental scheme stock
                                        around the UK. We have ready, consistent, institutional buyers of this type of property on a
                                        regular and efficient manner. Blocks should be unbroken and freehold inclusive. For buyers
                                        we have access to quality PRS stock.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src="assets/real_estate-3.png" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 style={{fontSize: '1rem'}} className="mb-3 card-title text-dark bold-font">Development Land</h5>
                                    <p style={{fontSize: '0.8rem'}} className="card-text text-dark">We have access to many types of land with and without planning in
                                        place. We are constantly seeking new land options for all types of development including
                                        but not limited to big box warehousing, residential development, energy plants, data
                                        centres where we have buyers that will buy outright or enter into joint ventures with land
                                        owners.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src="assets/real_estate-2.png" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 style={{fontSize: '1rem'}} className="mb-3 card-title text-dark bold-font">Commercial Real Estate</h5>
                                    <p style={{fontSize: '0.8rem'}} className="card-text text-dark">From prime central London offices to industrial warehousing in the
                                        cheapest areas of the UK we have institutional buyers of all types of off market commercial
                                        real estate assets. We are always seeking new off market commercial real estate options.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>

            <footer>
                <div className="row pt-5 pb-5">
                    <div className="col offset-1 col-10 text-center">
                        <p className="p-0 m-0 mb-3">
                            <span className="navbar-brand m-0 bold-font" style={{fontSize: '1.5rem'}}>FERGUSON</span>
                            <span className="navbar-brand m-0 " style={{fontSize: '1.5rem'}}>CAPITAL</span>
                        </p>
                        <p>00 (44) 777 5602904</p>
                        <p className="mb-3">71 – 75 SHELTON STREET, COVENT GARDEN, LONDON WC2H 9JQ</p>


                        <p className="p-0 m-0 text-uppercase">© Copyright 2021.</p>
                        <p className="p-0 m-0 text-uppercase" style={{fontSize: '.7rem'}}>
                            ferguson capital®. All rights reserved_ The contents of this document are the property of ferguson
                            capital®. They represent the intellectual property in the form of, but not limited to, processes, ideas
                            and creative designs. They may not be used without prior written agreement and only upon full compensation
                            to ferguson capital® for the use or partial use of any of the material contained herein.
                        </p>
                    </div>
                </div>
            </footer>


        </>
    );
}