import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import HomePage from './pages/home';
import ContactPage from './pages/contact';
import ProposalPage from './pages/proposal';
import RealEstatePage from './pages/real-estate';
import PrivateEquityPage from './pages/private-equity';
import VentureCapitalPage from './pages/venture-capital';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={'/'}>
          <HomePage />
        </Route>
        <Route exact path={'/contact'}>
          <ContactPage />
        </Route>
        <Route exact path={'/proposal'}>
          <ProposalPage />
        </Route>
        <Route exact path={'/real-estate'}>
          <RealEstatePage />
        </Route>
        <Route exact path={'/venture-capital'}>
          <VentureCapitalPage />
        </Route>
        <Route exact path={'/private-equity'}>
          <PrivateEquityPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
